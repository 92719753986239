.home-about {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media screen and (max-width: 1023px) {
    padding: 60px 0;
  }

  .about-container {
    @extend .container;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      gap: 40px;
    }

    .about-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (max-width: 1023px) {
        align-items: center;
      }

      .about-upHeading {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: $colorAccent1;
        margin: 0 0 8px;
      }

      .about-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 120%;
        color: $dark;
        margin: 0;

        @media screen and (max-width: 1023px) {
          text-align: center;
          font-size: 32px;
        }
      }

      .about-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: $lightText;
        margin: 32px 0;

        @media screen and (max-width: 1023px) {
          text-align: center;
          font-size: 16px;
        }
      }

      .about-button {
        background: $colorAccent1;
        border-radius: 18px;
        padding: 10px 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
        color: $light;
        margin-top: 16px;
        transition: transform ease-in-out 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .about-images {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media screen and (max-width: 767px) {
        width: 100%;
        align-items: center;
      }

      .about-imagePortrait {
        position: relative;
        z-index: 2;
        display: flex;
        width: 240px;
        border-radius: 24px;
        overflow: hidden;
        border: 8px solid $light;

        @media screen and (max-width: 767px) {
          width: 180px;
          transform: translateX(60px);
        }

        img {
          width: 100%;
        }
      }

      .about-imageLandscape {
        display: flex;
        width: 350px;
        border-radius: 16px;
        overflow: hidden;
        margin-top: -60px;
        transform: translateX(-40px);

        @media screen and (max-width: 767px) {
          width: 240px;
          transform: translateX(-20px);
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
