.home-boxes {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  background-color: $grey;

  @media screen and (max-width: 1023px) {
    padding: 40px 0;
  }

  .boxes-container {
    @extend .container;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 30px;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .boxes-item {
      position: relative;
      width: calc((100% - 30px) / 2);
      padding: 30px;
      background: $light;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 270px;
      overflow: hidden;

      @media screen and (max-width: 1023px) {
        width: 100%;
        max-width: 535px;
      }

      .item-content {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1;

        .item-heading {
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 180%;
          color: $dark;
          margin: 0;
        }

        .item-text {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          color: $lightText;
          margin: 0;
        }
      }

      .item-button {
        position: relative;
        z-index: 2;
        background: $colorAccent1;
        border-radius: 18px;
        padding: 10px 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
        color: $light;
        transition: transform ease-in-out 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }

      .icon-container {
        position: absolute;
        width: 100px;
        right: -8px;
        bottom: -8px;
        height: 100px;

        img {
          height: 100%;
          opacity: 0.2;
        }
      }
    }
  }
}
