.contact-addresses {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 100px;
  background-color: $light;

  @media screen and (max-width: 1023px) {
    padding: 0 0 40px;
  }

  .addresses-container {
    @extend .container;
    display: flex;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      gap: 40px;
    }

    .left-side {
      margin-right: 60px;
      padding-right: 60px;
      border-right: 1px solid rgba($dark, 0.25);
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media screen and (max-width: 1023px) {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }

      .addresses-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 120%;
        color: $dark;
        margin: 0;
      }

      .addresses-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        color: $lightText;
        margin: 0;
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      gap: 40px;
      min-width: 350px;

      .side-content {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: $dark;

        strong {
          font-size: 24px;
        }
      }
    }
  }
}
