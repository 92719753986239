.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $dark;
  padding: 145px 0 100px;

  @media screen and (max-width: 1023px) {
    padding: 40px 0;
  }

  .footer-container {
    @extend .container;
    display: flex;
    flex-direction: column;

    .footer-top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      padding-bottom: 50px;
      margin-bottom: 50px;
      border-bottom: 1px solid rgba($light, 0.5);

      @media screen and (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 40px;
        margin-bottom: 40px;
      }

      .footer-top-column {
        display: flex;
        align-items: center;

        .header-logo {
          display: flex;
          margin-right: 20px;

          & > img {
            height: 60px;
            border-radius: 60px;
          }
        }

        .footer-sitename {
          font-weight: 700;
          font-size: 16px;
          line-height: 17px;
          color: $light;
          margin: 0;
        }

        .footer-navigation {
          display: flex;
          align-items: center;
          gap: 50px;

          @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
          }

          .footer-link {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: $light;
          }
        }
      }
    }

    .footer-bottom {
      width: 100%;
      display: flex;
      gap: 100px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 40px;
      }

      .footer-column {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
        color: $light;
      }
    }
  }
}
