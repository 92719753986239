@keyframes showHeroBanner {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showHeroBannerText {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.home-hero-banner {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
  min-height: 600px;
  animation-name: showHeroBanner;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;

  @media screen and (max-width: 1023px) {
    min-height: 400px;
    padding: 60px 0;
  }

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba($dark, 0.2);
  }

  .hero-banner-container {
    position: relative;
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .hero-banner-text {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 120%;
      color: $light;
      margin: 0;
      max-width: 50%;
      opacity: 0;
      animation-name: showHeroBannerText;
      animation-duration: 0.8s;
      animation-delay: 0.6s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      @media screen and (max-width: 1023px) {
        font-size: 24px;
        max-width: 100%;
      }
    }

    .hero-banner-heading {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 120%;
      color: $light;
      margin: 8px 0 0;
      max-width: 50%;
      opacity: 0;
      animation-name: showHeroBannerText;
      animation-duration: 0.8s;
      animation-delay: 1s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      @media screen and (max-width: 1023px) {
        max-width: 100%;
        font-size: 48px;
      }
    }

    .hero-banner-button {
      background: $colorAccent1;
      border-radius: 18px;
      padding: 10px 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 17px;
      color: $light;
      margin-top: 16px;
      transition: transform ease-in-out 0.2s;
      opacity: 0;
      animation-name: showHeroBannerText;
      animation-duration: 0.8s;
      animation-delay: 1.6s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
