.home-numbers {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  background-color: $colorAccent1;

  @media screen and (max-width: 1023px) {
    padding: 0;
  }

  .numbers-container {
    @extend .container;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }

    .numbers-item {
      width: calc((100% - 2px) / 3);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid rgba($light, 0.5);
      gap: 12px;

      @media screen and (max-width: 1023px) {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid rgba($light, 0.5);
        padding: 40px 0;
      }

      &:last-child {
        border-right: none;
        border-bottom: none;
      }

      .top-text,
      .bottom-text {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: $light;
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }

      .number-text {
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 100%;
        text-align: center;
        color: $light;
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 32px;
        }
      }
    }
  }
}
