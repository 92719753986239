.contact-form {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media screen and (max-width: 1023px) {
    padding: 40px 0;
  }

  .form-container {
    @extend .container;
    display: flex;
    flex-direction: column;

    .form-message {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 180%;
      color: $light;
      margin: 0;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: rgb(89, 206, 143);
      margin-bottom: 16px;

      &.error {
        background-color: rgb(206, 89, 93);
      }
    }

    .form-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      background: $colorAccent1;
      border-radius: 16px;
      padding: 50px;
      gap: 50px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 20px;
      }

      .form-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        .form-heading {
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 120%;
          color: $light;
          margin: 0;
        }

        .form-text {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          color: $light;
          margin: 0;
          max-width: 430px;
        }
      }

      .form-element {
        width: 100%;
        max-width: 350px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 32px;

        @media screen and (max-width: 767px) {
          max-width: 100%;
        }

        .form-text,
        .form-textarea {
          width: 100%;
          background-color: transparent;
          color: $light;
          border: none;
          border-bottom: 1px solid $light;
          padding: 8px 16px;
          appearance: none;
          outline: none;
        }

        .form-button {
          filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
          background: $light;
          border-radius: 50px;
          padding: 16px 32px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          color: $colorAccent1;
          transition: transform ease-in-out 0.2s;
          border: none;
          appearance: none;
          outline: none;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
