.header {
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;

  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;

    .header-left {
      display: flex;
      align-items: center;
      gap: 50px;

      .header-logo {
        display: flex;

        & > img {
          height: 60px;
        }
      }

      .header-navigation {
        display: flex;
        align-items: center;
        gap: 50px;

        @media screen and (max-width: 1023px) {
          display: none;
        }

        .navigation-item {
          display: flex;
          position: relative;
          align-items: center;
          padding: 15px 0;

          &.has-children:hover .navigation-sublinks,
          &.has-children .navigation-sublinks:hover {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
          }

          &.has-children:hover .navigation-link:after {
            transform: rotate(180deg);
          }

          &.has-children .navigation-link:after {
            content: "";
            display: flex;
            margin-left: 10px;
            width: 9px;
            height: 5px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjQiIGZpbGw9Im5vbmUiPjxwYXRoIGZpbGw9IiMzMzMiIGQ9Ik00LjQ5MiA0YS41OTcuNTk3IDAgMCAxLS4zNjgtLjEyNkwuMjA5LjkyOEEuNDgyLjQ4MiAwIDAgMSAuMTM5LjE5LjU5Ni41OTYgMCAwIDEgLjk0Ny4xMjhMNC40OTIgMi44IDguMDYyLjEyOGEuNjE3LjYxNyAwIDAgMSAuODA2LjA2M2MuMjA3LjIzMS4xNi41NDctLjA3LjczN0w0Ljg4NSAzLjg3NEEuNzguNzggMCAwIDEgNC40OTIgNFoiLz48L3N2Zz4=");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: transform ease-in-out 0.2s;
          }

          .navigation-link {
            display: flex;
            align-items: center;
            font-weight: 700;
            line-height: 17px;
            color: $dark;
          }

          .navigation-sublinks {
            position: absolute;
            top: 90%;
            left: 0;
            transform: translateY(20px);
            display: flex;
            flex-direction: column;
            background-color: $light;
            border-radius: 6px;
            box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
            opacity: 0;
            padding: 10px 0;
            pointer-events: none;
            transition: all ease-in-out 0.2s;

            .navigation-sublink {
              padding: 10px 26px;
              font-weight: 700;
              line-height: 17px;
              color: $dark;
              text-align: center;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .header-right {
      display: flex;

      .header-button {
        font-weight: 700;
        line-height: 17px;
        color: $light;
        padding: 10px 16px;
        background-color: $colorAccent1;
        border-radius: 20px;
        transition: transform ease-in-out 0.2s;

        @media screen and (max-width: 1023px) {
          display: none;
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      .menu-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
        cursor: pointer;

        @media screen and (max-width: 1023px) {
          display: flex;
        }

        svg {
          height: 20px;
        }
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  inset: 0;
  display: none;
  flex-direction: column;
  background-color: $light;
  gap: 40px;
  z-index: 10;
  padding: 10px 20px 40px;

  &.is-active {
    @media screen and (max-width: 1023px) {
      display: flex;
    }
  }

  .top-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-logo {
      display: flex;

      & > img {
        height: 60px;
      }
    }

    .menu-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        height: 20px;
      }
    }
  }

  .header-navigation {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    .navigation-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .navigation-link {
        display: flex;
        align-items: center;
        font-weight: 700;
        line-height: 17px;
        color: $dark;
      }

      .navigation-sublinks {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-left: 20px;

        .navigation-sublink {
          display: flex;
          align-items: center;
          font-weight: 700;
          line-height: 17px;
          color: $dark;
        }
      }
    }

    .header-button {
      display: inline-block;
      width: auto;
      font-weight: 700;
      line-height: 17px;
      color: $light;
      padding: 10px 16px;
      background-color: $colorAccent1;
      border-radius: 20px;
      transition: transform ease-in-out 0.2s;
      margin-top: 20px;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
