.page-heading {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0 50px;
  background-color: $light;

  @media screen and (max-width: 1023px) {
    padding: 40px 0;
  }

  .heading-container {
    @extend .container;
    @extend .small;
    display: flex;

    .heading-wrapper {
      position: relative;
      width: 100%;
      min-height: 250px;
      display: flex;
      align-items: flex-end;
      padding: 30px;
      border-radius: 16px;
      overflow: hidden;
      background-position: center;
      background-size: cover;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba($colorAccent1, 0.5);
      }

      .heading-title {
        position: relative;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 120%;
        color: $light;
        margin: 0;
      }
    }
  }
}
