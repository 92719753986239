.page-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 50px;
  background-color: $light;

  @media screen and (max-width: 1023px) {
    padding: 40px 0;
  }

  .content-container {
    @extend .container;
    @extend .small;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .content-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      color: $lightText;

      a {
        color: $colorAccent1;
        text-decoration: underline;
      }
    }

    img {
      border-radius: 18px;
      overflow: hidden;
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 180%;
      color: $colorAccent1;
    }

    .page-image {
      width: 100%;
    }

    .page-gallery {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .gallery-item {
        width: calc((100% - 40px) / 3);

        @media screen and (max-width: 767px) {
          width: calc((100% - 20px) / 2);
        }
      }
    }

    .content-accordion {
      width: 100%;
      display: flex;
      flex-direction: column;

      .accordion-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $lightText;
        transition: padding 0.25s ease-in-out;

        &.is-opened {
          padding-bottom: 40px;

          .accordion-header::after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }

        .accordion-header {
          width: 100%;
          display: flex;
          position: relative;
          padding: 20px 70px 20px 0;
          cursor: pointer;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 28px;
            height: 28px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy42MDkgMjAuMDcyQzEzLjI5OTEgMjAuMDcyIDEyLjk5MjQgMjAuMDEwMSAxMi43MDY2IDE5Ljg5MDFDMTIuNDIwOSAxOS43NzAxIDEyLjE2MiAxOS41OTQzIDExLjk0NSAxOS4zNzNMNC42NzAwNSAxMS45NzFDNC4yMzYxNSAxMS41Mjk4IDMuOTk1MjkgMTAuOTM0MyA0LjAwMDQ1IDEwLjMxNTZDNC4wMDU2IDkuNjk2NzggNC4yNTYzNiA5LjEwNTQgNC42OTc1NSA4LjY3MTVDNS4xMzg3MyA4LjIzNzYxIDUuNzM0MjIgNy45OTY3NSA2LjM1Mjk5IDguMDAxOTFDNi45NzE3NyA4LjAwNzA2IDcuNTYzMTUgOC4yNTc4MiA3Ljk5NzA1IDguNjk5MDFMMTMuNjA5IDE0LjQwOUwxOS4yMTkgOC42OTkwMUMxOS40MzM2IDguNDgwMDcgMTkuNjg5MyA4LjMwNTYzIDE5Ljk3MTUgOC4xODU2OUMyMC4yNTM2IDguMDY1NzYgMjAuNTU2NyA4LjAwMjY5IDIwLjg2MzIgOC4wMDAwOEMyMS4xNjk4IDcuOTk3NDggMjEuNDczOSA4LjA1NTQxIDIxLjc1OCA4LjE3MDU0QzIyLjA0MjEgOC4yODU2NyAyMi4zMDA4IDguNDU1NzQgMjIuNTE5IDguNjcxQzIzLjQzOSA5LjU3MyAyMy40NTMgMTEuMDUxIDIyLjU0OSAxMS45NzFMMTUuMjczIDE5LjM3M0MxNS4wNTYgMTkuNTk0MiAxNC43OTcxIDE5Ljc2OTkgMTQuNTExNCAxOS44ODk5QzE0LjIyNTcgMjAuMDA5OSAxMy45MTg5IDIwLjA3MTggMTMuNjA5IDIwLjA3MloiIGZpbGw9IiMwMDQwNTMiLz4KPC9zdmc+Cg==");
            pointer-events: none;
            transition: transform 0.25s ease-in-out;

            @media screen and (max-width: 767px) {
              right: 0;
            }
          }

          .accordion-heading {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 180%;
            color: $colorAccent2;
          }
        }

        .accordion-content {
          width: 100%;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.25s ease-in-out;
          will-change: max-height;
          display: flex;
          flex-direction: column;

          a {
            color: $colorAccent1;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
