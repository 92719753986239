.cta {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;

  .cta-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $colorAccent1;
    border-radius: 16px;
    padding: 50px 100px;
    gap: 50px;
    transform: translateY(80px);
    overflow: hidden;

    @media screen and (max-width: 1023px) {
      transform: translateY(0);
      border-radius: 0;
      padding: 40px 20px;
      gap: 40px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .content-side {
      max-width: 475px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      .cta-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 120%;
        color: $light;
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 32px;
          width: 100%;
          text-align: center;
        }
      }

      .cta-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        color: $light;

        @media screen and (max-width: 767px) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .cta-button {
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
      background: $light;
      border-radius: 50px;
      padding: 16px 32px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 17px;
      text-align: center;
      color: $colorAccent1;
      transition: transform ease-in-out 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }

    .icon-container {
      position: absolute;
      width: 100px;
      right: -8px;
      bottom: -8px;
      height: 100px;

      img {
        height: 100%;
        opacity: 0.2;
      }
    }
  }
}
