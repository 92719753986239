// --------------------------------------------------
// Variables
// --------------------------------------------------

// Import Web Fonts
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap");

// Fonts
$fontArchivo: "Archivo", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";

// Accents Colors
$colorAccent1: #1e73be;
$colorAccent2: #004053;
$colorAccent3: #ffcb07;

$lightText: #808080;

// Texts
$dark: #333333;
$grey: #f7fafe;
$light: #ffffff;

$colorSuccess: #00966d;
$colorError: #ff564a;
