@keyframes showServices {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.home-services {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 100px 0;
  transform: translateY(30px);
  opacity: 0;
  animation-name: showServices;
  animation-duration: 0.8s;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  @media screen and (max-width: 1023px) {
    padding: 60px 0;
  }

  .services-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    .services-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .service-upHeading {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        color: $colorAccent1;
        margin: 0;
      }

      .service-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 120%;
        text-align: center;
        color: $dark;
        margin: 8px 0 0;
      }

      .service-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        text-align: center;
        color: $lightText;
        margin: 16px 0 0;
        max-width: 700px;
      }
    }

    .services-list {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;

      @media screen and (max-width: 1023px) {
        gap: 20px;
      }

      .services-item {
        width: calc((100% - 60px) / 3);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px;
        background: $light;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        @media screen and (max-width: 1023px) {
          width: calc((100% - 20px) / 2);
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .item-icon {
          display: flex;
          margin-bottom: 32px;

          & > img {
            height: 100px;
          }
        }

        .item-heading {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 180%;
          color: $dark;
          margin: 0;
        }

        .item-text {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          color: $lightText;
          margin: 8px 0 16px;
        }

        .item-button {
          background: $colorAccent1;
          border-radius: 18px;
          padding: 10px 16px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 17px;
          color: $light;
          margin-top: 16px;
          transition: transform ease-in-out 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
